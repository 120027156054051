
import CloudFun, { defineComponent, ref, Condition, Operator } from "@cloudfun/core";
import Grid, { GridOptions } from "@/cloudfun/components/Grid.vue";
import { VxeFormProps } from "vxe-table";
import SelectBox, {
  SelectBoxOptions
} from "@/cloudfun/components/SelectBox.vue";
import { VueUploadItem } from "vue-upload-component";

export default defineComponent({
  components: {
    Grid,
    SelectBox
  },
  setup() {
    const model = CloudFun.current?.model;
    const grid = ref<any>({});
    const uploader = ref<any>({});

    var printColumns = [{ field: "Name" }, { field: "Description" }];

    const gridOptions: GridOptions = {
      title: "攝影機",
      multiselect: false,
      toolbarConfig: {
        custom: true,
        refresh: true
      },
      printConfig: {
        sheetName: "攝影機清單",
        columns: printColumns,
        modes: ["current", "selected", "all"]
      },
      exportConfig: {
        filename: "攝影機清單",
        type: "csv",
        types: ["html", "csv"],
        mode: "all",
        modes: ["current", "selected", "all"],
        columns: printColumns
      },
      columns: [
        {
          field: "Id",
          title: "編號",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        },
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true,
          filters: [{ checked: false, label: "包含" }],
          slots: { filter: "filter-name" }
        },
        {
          field: "IvarName",
          title: "人流計數主機",
          showHeaderOverflow: true,
          showOverflow: true
        },
        {
          field: "ChannelType",
          title: "攝影機類型",
          showHeaderOverflow: true,
          showOverflow: true,
          formatter: ({ cellValue }) =>
            model
              ? Object.values(model.enums.ChannelType).find(
                e => e.Value === cellValue
              )?.Name
              : undefined
        },
        {
          field: "LinkUrl",
          title: "影像網址",
          showHeaderOverflow: true,
          showOverflow: true
        },
        {
          field: "Status",
          title: "狀態",
          showHeaderOverflow: true,
          showOverflow: true,
          formatter: ({ cellValue }) =>
            model
              ? Object.values(model.enums.DeviceStatus).find(
                e => e.Value === cellValue
              )?.Name
              : undefined
        },
      ],
      promises: {
        query: model
          ? params =>
            new Promise(resolve => {
              model.dispatch("channel/query", params).then(async channels => {
                const ivars = await model.dispatch("ivar/query", { condition: new Condition("Id", Operator.In, channels.data.map((e: any) => e.IvarId)) });
                channels.data.forEach((e: any) => {
                  e.IvarName = ivars.find((h: any) => h.Id === e.IvarId)?.Name;
                })
                resolve(channels);
              });
            })
          : undefined,
        queryAll: model ? () =>
          new Promise(resolve => {
            model.dispatch("channel/query").then(async channels => {
              const ivars = await model.dispatch("ivar/query", { condition: new Condition("Id", Operator.In, channels.data.map((e: any) => e.IvarId)) });
              channels.data.forEach((e: any) => {
                e.IvarName = ivars.find((h: any) => h.Id === e.IvarId)?.Name;
              })
              resolve(channels);
            });
          }) : undefined,
        save: model
          ? params => model.dispatch("channel/save", params)
          : undefined
      },
      modalConfig: { width: 600, showFooter: true }
    };

    const formOptions: VxeFormProps = {
      titleWidth: 110,
      titleAlign: "right",
      span: 3,
      items: [
        {
          field: "TempId",
          title: "編號",
          span: 12,
          slots: { default: "column-id" }
        },
        {
          field: "Name",
          title: "名稱",
          span: 12,
          itemRender: { name: "$input", props: { placeholder: "名稱" } }
        },
        {
          field: "ChannelType",
          title: "攝影機類型",
          span: 12,
          itemRender: {
            name: "$select",
            options: model
              ? Object.values(model.enums.ChannelType).map(e => {
                return { label: e.Name, value: e.Value };
              })
              : []
          }
        },
        {
          field: "LocationType",
          title: "位置類型",
          span: 12,
          itemRender: {
            name: "$select",
            options: model
              ? Object.values(model.enums.LocationType).map(e => {
                return { label: e.Name, value: e.Value };
              })
              : []
          }
        },
        {
          field: "Status",
          title: "狀態",
          span: 12,
          itemRender: {
            name: "$select",
            options: model
              ? Object.values(model.enums.DeviceStatus).map(e => {
                return { label: e.Name, value: e.Value };
              })
              : []
          }
        },
        {
          field: "IvarId",
          title: "人流計數主機",
          span: 24,
          slots: { default: "column-ivar-id" }
        },
        {
          field: "LinkUrl",
          title: "影像網址",
          span: 24,
          itemRender: { name: "$input" }
        },
        {
          field: "Remark",
          title: "備註",
          span: 24,
          itemRender: { name: "$textarea" }
        }
      ],
      rules: {
        TempId: [{ required: true }],
        Name: [{ required: true }],
        ChannelType: [{ required: true }],
        LocationType: [{ required: true }],
        Status: [{ required: true }],
        IvarId: [{ required: true }],
      }
    };

    if (CloudFun.current?.policy?.isApproved(CloudFun.current.user!, "Administrator")) {
      formOptions.items?.splice(4, 0,
        { field: "EnterWeight", title: "進入人數權重", span: 12, itemRender: { name: "$input" } },
        { field: "LeaveWeight", title: "離開人數權重", span: 12, itemRender: { name: "$input" } },
      )
    }

    const selectorOptions: SelectBoxOptions = {
      rowId: "Id",
      transfer: true,
      placeholder: "選擇主機",
      textField: "Name",
      valueField: "Id",
      columns: [
        {
          field: "Name",
          title: "名稱",
          showHeaderOverflow: true,
          showOverflow: true,
          sortable: true
        }
      ],
      showHeader: true,
      promises: {
        find: value => model!.dispatch("ivar/find", value), // eslint-disable-line
        query: params => model!.dispatch("ivar/query", params) // eslint-disable-line
      }
    };

    return {
      grid,
      uploader,
      gridOptions,
      formOptions,
      selectorOptions,
      uploadAction: `${process.env.VUE_APP_BACKEND_URL}/api/files`
    };
  },
  methods: {
    hideBatchDropDown() {
      cash("#batch-dropdown").dropdown("hide");
    },
    async uploaderFilter(
      current: VueUploadItem,
      original: VueUploadItem,
      prevent: any
    ) {
      if (!current || !current.name) return prevent();
      if (!/\.(png|gif|jpg|jpeg)$/i.test(current.name)) {
        alert("未支援此種圖片格式");
        return prevent();
      }
    }
  }
});
